// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAAmLrIhsXp1vcTmT0kVArHhiCOW_l-xA8",
    authDomain: "visana-4ad41.firebaseapp.com",
    databaseURL: "https://visana-4ad41.firebaseio.com",
    projectId: "visana-4ad41",
    storageBucket: "visana-4ad41.appspot.com",
    messagingSenderId: "907508077361",
    appId: "1:907508077361:web:cc94f48ebf6b6dfdd31610",
    measurementId: "G-RDES6T53CN"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
